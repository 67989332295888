@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css);


html {
  height: 100%;
}

html body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: rgba(0, 0, 0, 0.03);
  padding-top: 40px;
}

.Header > .navbar-brand {
  font-size: 20px;
  padding: 0 0px;
}

.navbar-brand > .Logo {
  margin: 0.5rem;
}

.Header {
  background-color: #fff;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
  min-height: 40px;
  transition: all 0.4s ease;
}

.nav-link:hover {
  color: #00a8a8;
}

.Header.shrink {
  min-height: 40px;
}

.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
}

#Banner {
  background: url(/static/media/cover_cropped_450px.d31005f0.jpg) no-repeat;
  background-position: 50% 25%;
  height: 300px;
  background-size: cover;
  max-width: 100%;
  z-index: 1;
}

.main {
  position: relative;
  margin: -150px auto 0px;
  z-index: 2;
}

.nav-link {
  font-size: 18px;
}

.card {
  border-top: 10px solid #00a8a8;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03);
}

.Profile {
  position: relative;
  margin: -175px auto 0;
  padding: 7.5px;
}

.PortfolioItem {
  padding-bottom: 30px;
  /* border: solid 1px rgba(0, 0, 0.50); */
}

.PortfolioItem > img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.PortfolioItemIcon {
  font-size: 24px;
  margin: 2px;
  color: #00a8a8;
}

.PortfolioItemIcon:hover {
  text-decoration: none;
  color: #008080;
}

.created-by > a {
  color: #00a8a8;
  text-decoration: none;
}

i.fa {
  font-size: 18px;
}

.Footer {
  background-color: #fff;
  height: 40px;
}

@media (min-width: 768px) {
  html body {
    padding-top: 40px;
  }

  .Header > .navbar-brand {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    padding: 0 15px;
  }

  #Banner {
    height: 450px;
  }

  .created-by {
    font-size: 1em;
  }
}

